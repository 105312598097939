import React, { useState, useEffect } from "react";
import Layout from '../components/Layout';
import ItemDisplay from '../components/ItemDisplay';
import Item from "../interfaces/item";
import { useQuery, useLazyQuery } from "@apollo/client";
import { RON_TO_USD_QUERY, FILTER_ITEMS_QUERY, GET_ITEM_DETAILS_QUERY } from "../services/apiService"
import { getEneryRestoredFromPotion, priceCalc, getItem, mapTokenToItem } from "../services/helperServices";

const Home: React.FC = () => {
    const [itemData, setItemData] = useState<Item[]>([]);
    const [pinnedItemIds, setPinnedItemIds] = useState<string[]>([]);
    const [pinnedItems, setPinnedItems] = useState<Item[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [pageValue, setPageValue] = useState(0);
    const [showAttributes, setShowAttributes] = useState(true);
    const [ronToUSDValue, setRonToUSDValue] = useState(0);

    // Load pinned items from local storage
    useEffect(() => {
        const fetchData = async () => {
            const storedNumbers = localStorage.getItem('pinnedItems');
            if (storedNumbers) {
                const array = JSON.parse(storedNumbers) as string[];
                let pinnedItemArray: Item[] = [];
                setPinnedItemIds(array);
                let asyncMapResult = array.map(async (id) => {
                    let item = await getItem(id, getItemDetailsQuery);
                    pinnedItemArray.push(item);
                });

                await Promise.all(asyncMapResult);
                setPinnedItems(pinnedItemArray);
                //console.log(array);
            }

        }

        fetchData();

        return () => {
        }
    }, []);

    useQuery(RON_TO_USD_QUERY, {
        fetchPolicy: 'cache-first', // Change this to control caching behavior
        onCompleted: (response) => {
            setRonToUSDValue(response.exchangeRate.ron.usd);
            localStorage.setItem('ronToUSDValue', response.exchangeRate.ron.usd);
        }
    });

    useQuery(FILTER_ITEMS_QUERY, {
        variables: { name: inputValue, from: Math.max((pageValue - 1) * 50, 0) },
        onCompleted: (response) => {
            setItemData([]);
            response.erc1155Tokens.results.map((e: any) => {
                setItemData(array => [
                    ...array, mapTokenToItem(e.tokenId, e)
                ]);
            });
        }
    });

    const [getItemDetailsQuery, { loading, error, data }] = useLazyQuery(GET_ITEM_DETAILS_QUERY);

    const isPinned = (tokenId: string) => {
        return pinnedItemIds.indexOf(tokenId) > -1;
    }

    const togglePinned = async (tokenId: string) => {
        let itemIdArray = pinnedItemIds;

        if (isPinned(tokenId)) {
            itemIdArray = itemIdArray.filter(item => item !== tokenId)
            setPinnedItems(array => array.filter(item => item.tokenId !== tokenId));
            setPinnedItemIds(itemIdArray);
        }
        else {
            itemIdArray.push(tokenId)
            setPinnedItemIds(itemIdArray);

            let item = await getItem(tokenId, getItemDetailsQuery);
            setPinnedItems(array => [...array, item]);
        }

        localStorage.setItem('pinnedItems', JSON.stringify(itemIdArray));
    };

    if (!pinnedItemIds) return <div>loading</div>

    return (
        <Layout>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                {
                    Array.from(new Set(pinnedItems))
                        .sort((a, b) => a.name > b.name ? 1 : -1)
                        .map(e => (
                            <ItemDisplay key={e.tokenId} Item={e} showAttributes={showAttributes}>
                                <a onClick={() => togglePinned(e.tokenId)} style={{ cursor: "pointer", color: "white", textDecoration: "underline" }}>
                                    {(isPinned(e.tokenId) ? "unpin" : "pin")}
                                </a>
                            </ItemDisplay>
                        ))
                }
            </div>
            <hr />
            <div>
                <input type="text" onChange={(e) => setInputValue(e.target.value)} placeholder="filter items per name"></input>
            </div>
            <div>
                <label><input type='checkbox' onChange={e => setShowAttributes(e.target.checked)}></input> show attributes</label>
            </div>
            <hr />
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                {
                    Array.from(new Set(itemData))
                        .sort((a, b) => priceCalc(a.minPrice) > priceCalc(b.minPrice) ? 1 : -1)
                        .map(e =>
                        (
                            <ItemDisplay key={e.tokenId} Item={e} showAttributes={showAttributes}>
                                <a onClick={() => togglePinned(e.tokenId)} style={{ cursor: "pointer", color: "white", textDecoration: "underline" }}>
                                    {(isPinned(e.tokenId) ? "unpin" : "pin")}
                                </a>
                            </ItemDisplay>
                        ))
                }
            </div>
        </Layout >
    );
};

export default Home;
