import { useQuery, gql, useLazyQuery, useApolloClient } from "@apollo/client";
import Item from "../interfaces/item"
    
export const GET_POTION_DETAILS_QUERY = gql`
    query MyQuery($tokenId: BigDecimal!)  {        
        erc1155Token(
            tokenAddress: "0xcc451977a4be9adee892f7e610fe3e3b3927b5a1"
            tokenId: $tokenId
        ) {
            attributes,
            minPrice,
            name,
            cdnImage
        }
}`;

export const FILTER_ITEMS_QUERY = gql`
query Dog($name: String!, $from: Int!) 
{
    erc1155Tokens(
        from: $from
        size: 50
        tokenAddress: "0xcc451977a4be9adee892f7e610fe3e3b3927b5a1"
        name: $name
        auctionType: Sale
        rangeCriteria: []
    ) {
        results {
            minPrice
            name,
            tokenId,
            cdnImage,
            attributes
        }
    }
}
`;

export const FILTER_SET_QUERY = gql`
query MyQuery($criteria: [SearchCriteria!], $rangeCriteria: [RangeSearchCriteria!])  {
  erc1155Tokens(
    auctionType: Sale
    from: 0
    size: 50
    tokenAddress: "0xcc451977a4be9adee892f7e610fe3e3b3927b5a1"
    criteria: $criteria
    rangeCriteria: $rangeCriteria
  ) {
    results {
        tokenId
        attributes
        cdnImage
        name
        minPrice
    }
  }
}
`

export const GET_ITEM_DETAILS_QUERY = gql`
    query MyQuery($tokenId: BigDecimal!)  {
        erc1155Token(tokenAddress: "0xcc451977a4be9adee892f7e610fe3e3b3927b5a1", tokenId: $tokenId) {            
          attributes
          cdnImage
          name
          minPrice
        }
}`;

export const RON_TO_USD_QUERY = gql`
query MyQuery {
  exchangeRate {
    ron {
      usd
    }
  }
}`;