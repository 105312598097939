import React, { useState, useEffect } from "react";
import { UserData } from '../interfaces/UserData'
import Item from "../interfaces/item";
import { useLazyQuery, useQuery, useApolloClient } from "@apollo/client";
import { GET_POTION_DETAILS_QUERY, RON_TO_USD_QUERY } from "../services/apiService"
import Linky from "../components/Linky"

interface TemplateProps {
    children: React.ReactNode;
}

const Template: React.FC<TemplateProps> = ({ children }) => {
    let userDataLoaded = false;
    const [userData, setUserData] = useState<UserData>();
    const [ronToUSDValue, setRonToUSDValue] = useState(0);
    const [getPotionDetails, { loading, error, data }] = useLazyQuery(GET_POTION_DETAILS_QUERY);
    const client = useApolloClient();

    useQuery(RON_TO_USD_QUERY, {
        fetchPolicy: 'cache-first', // Change this to control caching behavior
        onCompleted: (response) => {
            if (ronToUSDValue == 0) {
                setRonToUSDValue(response.exchangeRate.ron.usd);
            }
        }
    });

    useEffect(() => {
        console.log('useEffect call');
        if (userDataLoaded) return;
        // const existingUserData = localStorage.getItem('userData');
        const userDataString = localStorage.getItem('userData');

        const fetchData = async () => {
            try {
                let potionId = '268616976';
                if (userDataString) {
                    const existingUserData = JSON.parse(userDataString) as UserData;
                    if (existingUserData) {
                        // load saved data to react state
                        setUserData(existingUserData);
                        if (existingUserData) {
                            potionId = existingUserData.selectedEnergyPotionId;
                        }
                    }
                }

                var potionDetails = await updateCurrentPotion(potionId);
                localStorage.setItem('userData', JSON.stringify(potionDetails));

                setUserData({
                    currentCostPerEnergy: parseFloat(potionDetails.currentCostPerEnergy.toFixed(3)),
                    selectedEnergyPotionId: potionDetails.selectedEnergyPotionId,
                });

            } catch (error) {
                console.error("Failed to fetch data", error);
            }

            userDataLoaded = true;
        }

        fetchData();
        return () => { }
    }, []);

    const updateCurrentPotion = async (tokenId: string) => {
        const response = await getPotionDetails({ variables: { tokenId } });
        console.log(response.data.erc1155Token);
        var potionRestoredEnergy = response.data.erc1155Token.attributes['restore energy'][0];
        var itemData = response.data.erc1155Token as Item;
        var pricePerEnergy = itemData.minPrice / 1000000000000000000 / parseInt(potionRestoredEnergy);
        console.log('Potion cost: ' + (itemData.minPrice / 1000000000000000000).toFixed(3));
        console.log('Energy restored: ' + potionRestoredEnergy);

        return {
            currentCostPerEnergy: parseFloat(pricePerEnergy.toFixed(3)),
            selectedEnergyPotionId: tokenId,
        };
    }

    const selectEnergyPotion = async (e: any) => {
        var tokenId = e.target.value;
        var potionDetails = await updateCurrentPotion(tokenId);

        setUserData({
            currentCostPerEnergy: parseFloat(potionDetails.currentCostPerEnergy.toFixed(3)),
            selectedEnergyPotionId: tokenId,
        });

        localStorage.setItem('userData', JSON.stringify(potionDetails));
    }

    if (!userData) return (<div>'Loading...'</div>)

    return (
        <div>
            <div className="container" style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div>
                        lumi lumi
                    </div>

                    <div>
                        energy potion used: <select defaultValue={userData?.selectedEnergyPotionId} onChange={selectEnergyPotion}>
                            <option value="268616976">level 1 energy restoration potion</option>
                            <option value="268617232">level 2 energy restoration potion</option>
                            <option value="268617488">level 3 energy restoration potion</option>
                            <option value="268617744">level 4 energy restoration potion</option>
                            <option value="268618000">level 5 energy restoration potion</option>
                            <option value="268618256">level 6 energy restoration potion</option>
                        </select>
                    </div>
                    <div>
                        CPE: {userData?.currentCostPerEnergy} RON ({(userData?.currentCostPerEnergy * ronToUSDValue).toFixed(3)} USD)
                    </div>
                </div>
            </div>

            <div className="container" style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                    <div style={{ margin: "10px" }}>
                        <Linky to="/crafting" className="">crafting</Linky>
                    </div>
                    <div style={{ margin: "10px" }}>
                        <Linky to="/" className="">marketplace</Linky>
                    </div>
                    <div style={{ margin: "10px" }}>
                        <Linky to="/energypotions" className="">energy potions</Linky>
                    </div>
                    <div style={{ margin: "10px" }}>
                        <Linky to="/setbuilder" className="">set builder</Linky>
                    </div>
                    <div style={{ margin: "10px" }}>
                        <Linky to="/setbuilder" className="">set builder</Linky>
                    </div>
                </div>

                {children}

                <hr />

                <div className="footer" style={{ fontSize: "8pt" }}>
                    <a href="https://x.com/lotuzxcorp" target="_blank">lotuz @lotuzxcorp</a>
                </div>
            </div >
        </div >
    );
};

export default Template;
