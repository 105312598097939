import React, { useState, useEffect } from "react";
import Layout from '../components/Layout';
import Item from "../interfaces/item";
import { useQuery, useLazyQuery } from "@apollo/client";
import { getRonToUSD, displayPrice, displayPriceLabel, getItem, priceCalc } from "../services/helperServices"
import { RON_TO_USD_QUERY, FILTER_ITEMS_QUERY, GET_ITEM_DETAILS_QUERY } from "../services/apiService"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Crafting: React.FC = () => {
    const [itemData, setItemData] = useState<Item[]>([]);
    const [pinnedItemIds, setPinnedItemIds] = useState<string[]>([]);
    const [pinnedItems, setPinnedItems] = useState<Item[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [pageValue, setPageValue] = useState(0);
    const [ronToUSDValue, setRonToUSDValue] = useState(0);

    const gatherEssenceIds = [1101584, 1101840, 1102096, 268538896, 268539152, 268539408, 268590352, 268590608]
    const combatEssenceIds = [1103888, 1104144, 1104400, 268538128, 268538384, 268538640, 268589584, 268589840]
    const farmingLivestockEssenceIds = [1103120, 1103376, 1103632, 268540432, 268540688, 268540944, 268591888, 268592144]
    const farmingPlantingEssenceIds = [1102352, 1102608, 1102864, 268539664, 268539920, 268540176]

    const lv1GatherResources = []
    const lv2GatherResources = []
    const lv3GatherResources = []
    const lv4GatherResources = []
    const lv5GatherResources = []
    const lv6GatherResources = []

    const lv1CombatResources = []
    const lv2CombatResources = []
    const lv3CombatResources = []
    const lv4CombatResources = []
    const lv5CombatResources = []
    const lv6CombatResources = []

    const lv1PlantingSeeds = [1086480, 1085968, 1086224]
    const lv2PlantingSeeds = []
    const lv3PlantingSeeds = []
    const lv4PlantingSeeds = []
    const lv5PlantingSeeds = []
    const lv6PlantingSeeds = []

    const lv1PlantingResources = []
    const lv2PlantingResources = []
    const lv3PlantingResources = []
    const lv4PlantingResources = []
    const lv5PlantingResources = []
    const lv6PlantingResources = []

    const slimeIds = [268558864, 268559120, 268559376, 268559632, 268559888, 268560144, 268560400]

    useEffect(() => {
        const fetchData = async () => {
            var slimeArray: Item[] = []
            if (slimeIds) {
                let asyncMap = slimeIds.map(async (id) => {
                    slimeArray.push(await getItem(id.toString(), getItemDetails));
                });

                const results = await Promise.all(asyncMap);
                console.log(slimeArray);
                setItemData(slimeArray);
            }
        }

        fetchData();

        return () => {
        }
    }, []);

    useQuery(RON_TO_USD_QUERY, {
        fetchPolicy: 'cache-first', // Change this to control caching behavior
        onCompleted: (response) => {
            if (ronToUSDValue == 0) {
                setRonToUSDValue(response.exchangeRate.ron.usd);
            }
        }
    });

    const [getItemDetails, { loading, error, data }] = useLazyQuery(GET_ITEM_DETAILS_QUERY);

    if (!pinnedItemIds) return <div>loading</div>

    return (
        <Layout>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>item</TableCell>
                                <TableCell>level</TableCell>
                                <TableCell>floor</TableCell>
                                <TableCell>profit</TableCell>
                                <TableCell>profit/energy when farming all</TableCell>
                                <TableCell>profit/energy when buying lower</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                itemData
                                    .sort((a, b) => a.name > b.name ? 1 : -1)
                                    .map((e, i) =>
                                    (
                                        <TableRow
                                            key={e.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {e.name}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {(e.attributes == null || e.attributes['requires level'] == null ? "" : e.attributes['requires level'][0])}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {displayPrice(e)}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {displayPrice(e, true)}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {
                                                    displayPriceLabel((e.attributes == null || e.attributes['requires level'] == null ? 0 :
                                                        priceCalc(e.minPrice * 0.9 / (12 * e.attributes['requires level'][0]))))
                                                }
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {
                                                    displayPriceLabel(i == 0 || e.attributes == null || e.attributes['requires level'] == null ? 0 :
                                                        priceCalc(((e.minPrice * 0.9) - (itemData[i - 1].minPrice)) / 12))
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Layout >
    );
};

export default Crafting;
