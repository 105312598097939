import React, { useState, useEffect } from "react";
import Layout from '../components/Layout';
import ItemDisplay from '../components/ItemDisplay';
import Item from "../interfaces/item";
import { getEneryRestoredFromPotion, priceCalc, getItem } from "../services/helperServices";
import { useQuery, useLazyQuery, LazyQueryExecFunction, OperationVariables } from "@apollo/client";
import { RON_TO_USD_QUERY, GET_POTION_DETAILS_QUERY } from "../services/apiService"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const EnergyPotions: React.FC = () => {
    const [itemData, setItemData] = useState<Item[]>([]);
    const [ronToUSDValue, setRonToUSDValue] = useState(0);
    const energyPotionIds = ['268616976', '268617232', '268617488', '268617744', '268618000', '268618256']

    const loadDataFromLocalStorage = () => {
        const jsonString = localStorage.getItem('energyPotions');
        if (jsonString) {
            const array = JSON.parse(jsonString) as Item[];
            setItemData(array)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            let array: Item[] = []
            const asyncMapResult = energyPotionIds.map(async (tokenId) => {
                console.log('loading potion data for item ' + tokenId)
                let item = await getItem(tokenId, getItemDetailsQuery);
                array.push(item);
            });

            const results = await Promise.all(asyncMapResult);
            console.log('setting item data array to state')
            setItemData(array);
            localStorage.setItem('energyPotions', JSON.stringify(array))
        }

        loadDataFromLocalStorage();
        fetchData();

        return () => {
        }
    }, []);


    useQuery(RON_TO_USD_QUERY, {
        fetchPolicy: 'cache-first', // Change this to control caching behavior
        onCompleted: (response) => {
            if (ronToUSDValue == 0) {
                setRonToUSDValue(response.exchangeRate.ron.usd);
            }
        }
    });

    const [getItemDetailsQuery, { loading, error, data }] = useLazyQuery(GET_POTION_DETAILS_QUERY);

    if (!itemData) return <div>loading</div>

    return (
        <Layout>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>item</TableCell>
                                <TableCell>energy</TableCell>
                                <TableCell>floor</TableCell>
                                <TableCell>price per energy</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                itemData
                                    .sort((a, b) => a.name > b.name ? 1 : -1)
                                    .map(e =>
                                    (
                                        <TableRow
                                            key={e.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <img style={{ width: "32px" }} src={e.cdnImage}></img>

                                            </TableCell>

                                            <TableCell component="th" scope="row">

                                                {e.name}

                                            </TableCell>

                                            <TableCell component="th" scope="row">

                                                {e.energy}

                                            </TableCell>

                                            <TableCell component="th" scope="row">

                                                {priceCalc(e.minPrice).toFixed(3)} ({(priceCalc(e.minPrice) * ronToUSDValue).toFixed(2)} USD)

                                            </TableCell>

                                            <TableCell component="th" scope="row">

                                                {e.energy != null ? priceCalc(e.minPrice / e.energy).toFixed(3) : "--"}  ({e.energy != null ? (priceCalc(e.minPrice / e.energy) * ronToUSDValue).toFixed(2) : "--"} USD)

                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Layout >
    );
};


export default EnergyPotions;