import React, { useState, useEffect } from "react";
import Layout from '../components/Layout';
import Item from "../interfaces/item";
import ItemDisplay from "../components/ItemDisplay";
import { useQuery, useLazyQuery } from "@apollo/client";
import { getRonToUSD, displayPrice, displayPriceLabel, getItem, priceCalc, mapTokenToItem, getAttributeValue } from "../services/helperServices"
import { RON_TO_USD_QUERY, FILTER_SET_QUERY, GET_ITEM_DETAILS_QUERY } from "../services/apiService"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Input, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { isNullOrUndefined } from "util";
import { error } from "console";
import { getVariableValues } from "graphql";

const SetBuilder: React.FC = () => {
    const [itemData, setItemData] = useState<Item[]>();
    const [glovesData, setGlovesData] = useState<Item[]>();
    const [bootsData, setBootsData] = useState<Item[]>();
    const [armorData, setArmorData] = useState<Item[]>();
    const [pantsData, setPantsData] = useState<Item[]>();
    const [helmetData, setHelmetData] = useState<Item[]>();

    const [enableHeadSearch, setEnableHeadSearch] = useState<boolean>(true);
    const [enableChestSearch, setEnableChestSearch] = useState<boolean>(true);
    const [enableHandSearch, setEnableHandSearch] = useState<boolean>(true);
    const [enableLegsSearch, setEnableLegsSearch] = useState<boolean>(true);
    const [enableFeetSearch, setEnableFeetSearch] = useState<boolean>(true);

    const [bestItemsData, setBestItemsData] = useState<Item[]>();
    const [currentProficiencyData, setCurrentProficiencyData] = useState<string>("100");
    const [goalProficiencyData, setGoalProficiencyData] = useState<string>("200");
    const [resultLabelData, setResultLabelData] = useState<string>();
    const [proficiencyTypeData, setProficiencyTypeData] = useState<string>();
    const [maxLevel, setMaxLevel] = useState<number>(8);
    const [minLevel, setMinLevel] = useState<number>(1);
    const [loading, setLoading] = React.useState(false);
    const [proficiencyLabel, setProficiencyLabel] = useState<string>();

    const [ronToUSDValue, setRonToUSDValue] = useState(0);
    const [getFilterSetQuery, { }] = useLazyQuery(FILTER_SET_QUERY);
    const [getItemDetailsQuery, { }] = useLazyQuery(GET_ITEM_DETAILS_QUERY);

    const gatheringAll = 'gatheringall';
    const gatheringAttributes = ['grassproficiency', 'treeproficiency', 'oreproficiency'];
    const proficiencyTypeLabels = ['gathering grass', 'gathering tree', 'gathering ore'];

    const createVariablesFilter = (itemType: string, levels: number[]) => {
        var variables: any = {
            variables: {
                criteria: [
                    {
                        name: "requires level",
                        values: levels.map(e => e.toString())
                    },
                    {
                        name: "type",
                        values: [itemType]
                    },
                ]
            },
        };

        if (proficiencyLabel == gatheringAll) {
            variables.rangeCriteria = [
                { name: gatheringAttributes[0], range: { from: 1, to: "999" } },
                { name: gatheringAttributes[1], range: { from: 1, to: "999" } },
                { name: gatheringAttributes[2], range: { from: 1, to: "999" } },
            ]
        }
        else {
            variables.rangeCriteria = [
                { name: proficiencyTypeData, range: { from: 1, to: "999" } }
            ]
        };

        return variables;
    }

    const getFilterQueryByItem = async (itemType: string, levels: number[]) => {
        var variables = createVariablesFilter(itemType, levels);
        const itemQueryResults = await getFilterSetQuery(variables);

        var items = itemQueryResults.data.erc1155Tokens.results.map((e: any) => mapTokenToItem(e.tokenId, e)) as Item[];

        for (let i = 0; i < items.length; i++) {
            if (items[i].minPrice == null) {
                console.log('found item with null price, refreshing data...')
                var variables = createVariablesFilter(itemType, levels);
                items[i] = await getItem(items[i].tokenId, getItemDetailsQuery);
                console.log(items[i])
            }
        }

        return items;
    }

    const fillEfficiency = (items: Item[]) => {
        if (!proficiencyTypeData) return items;

        for (let i = 0; i < items.length; i++) {
            if (items[i].minPrice == null) {
                removeItem(items, items[i]);
                i--;
                continue;
            }

            if (proficiencyTypeData === gatheringAll) {
                items[i].stat1 = parseFloat(getAttributeValue(items[i], gatheringAttributes[0]));
                items[i].stat2 = parseFloat(getAttributeValue(items[i], gatheringAttributes[1]));
                items[i].stat3 = parseFloat(getAttributeValue(items[i], gatheringAttributes[2]));
                items[i].efficiency = (items[i].stat2 + items[i].stat2 + items[i].stat3) / priceCalc(items[i].minPrice);
            }
            else {
                items[i].stat = parseFloat(getAttributeValue(items[i], proficiencyTypeData));
                items[i].efficiency = items[i].stat / priceCalc(items[i].minPrice);
            }
        }

        return items.sort((a, b) => a.efficiency > b.efficiency ? -1 : 1).filter((e, i) => i < 15);
    }

    const getAllItems = async () => {
        setLoading(true)
        runSetFinder2();
    }

    function removeItem<T>(arr: Array<T>, value: T): Array<T> {
        const index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    const runSetFinder2 = async () => {
        if (!proficiencyLabel) {
            setResultLabelData('Select a proficiency type first.');
            setLoading(false)
            return;
        }

        setResultLabelData('Running set builder...');

        console.log(new Date())
        console.log('Loading item arrays from api')

        let levels: number[] = []
        for (let i = minLevel ?? 1; i <= maxLevel ?? 8; i++) {
            levels.push(i);
        }

        console.log(new Date())
        console.log('Done loading item arrays')

        const blankItem = { name: '', cdnImage: '', tokenId: '0', minPrice: 0, stat: 0, stat1: 0, stat2: 0, stat3: 0 } as Item;

        let arrayOfArrays: string[][] = []
        let allItems: Item[] = []

        const addSetPartItems = async (enable: boolean, queryFilter: string) => {
            if (enable) {
                let itemArray = await getFilterQueryByItem(queryFilter, levels);
                itemArray = fillEfficiency(itemArray);
                setHelmetData(itemArray);
                itemArray.push(blankItem);
                allItems.push(...itemArray);
                arrayOfArrays.push(itemArray.map(e => e.tokenId));
            }
        }

        await addSetPartItems(enableHeadSearch, "head armor");
        await addSetPartItems(enableChestSearch, "chest armor");
        await addSetPartItems(enableHandSearch, "hands armor");
        await addSetPartItems(enableLegsSearch, "legs armor");
        await addSetPartItems(enableFeetSearch, "feet armor");

        console.log(new Date())
        console.log(arrayOfArrays)
        let length = 1;
        for (let i = 0; i < arrayOfArrays.length; i++) {
            length *= arrayOfArrays[i].length;
        }
        console.log('Will generate a total of ' + length + ' set iterations')

        var proficiencyRequired = parseInt(goalProficiencyData ?? '100') - parseInt(currentProficiencyData ?? '240');
        var bestPrice = -1;
        let bestItemArray: string[] = [];

        function combineArraysUsingReduce(arrays: any[][]): any[][] {
            return arrays.reduce((acc, curr) => {
                return acc.flatMap(a => curr.map(b => [...a, b]));
            }, [[]]);
        }

        console.log(new Date())
        console.log('Generating set combinations')
        try {
            const combinations = combineArraysUsingReduce(arrayOfArrays);
            console.log(new Date())
            console.log('Finished item combinations')
            console.log('Generated a total of ' + combinations.length + ' set iterations')

            var step = 1;
            var combinationStepSize = 100000;

            for (let i = 0; i < combinations.length; i++) {
                if (i > combinationStepSize * step) {
                    step++;
                    console.log('processing ' + i + '/' + combinations.length)
                }

                var priceSum = 0;
                var statSum = 0;
                var stat1Sum = 0;
                var stat2Sum = 0;
                var stat3Sum = 0;

                for (let j = 0; j < combinations[i].length; j++) {
                    const item = allItems.filter(x => x.tokenId == combinations[i][j])[0];

                    if (item.stat > 0 && item.minPrice == 0) continue;
                    if (proficiencyTypeData == gatheringAll) {
                        stat1Sum += item.stat1;
                        stat2Sum += item.stat2;
                        stat3Sum += item.stat3;
                    }
                    else {
                        statSum += item.stat;
                    }
                    priceSum += priceCalc(item.minPrice);
                }

                if (proficiencyTypeData == gatheringAll) {
                    if (stat1Sum < proficiencyRequired || stat2Sum < proficiencyRequired || stat3Sum < proficiencyRequired) {
                        continue;
                    }
                }
                else {
                    if (statSum < proficiencyRequired) {
                        continue;
                    }
                }

                // if (proficiencyTypeData == gatheringAll) {
                //     console.log('Found itens with total proficiency of ' + stat1Sum + ', ' + stat2Sum + ', ' + stat3Sum + ' for a total price of ' + displayPriceLabel(priceSum));
                // }
                // else {
                //     console.log('Found itens with total proficiency of ' + statSum + ' for a total price of ' + displayPriceLabel(bestPrice));
                // }

                if (bestPrice == -1 || bestPrice > priceSum) {
                    bestPrice = priceSum;
                    bestItemArray = combinations[i] as string[];

                    if (proficiencyTypeData == gatheringAll) {
                        setResultLabelData('Found itens with total proficiency of ' + stat1Sum + ', ' + stat2Sum + ', ' + stat3Sum + ' for a total price of ' + displayPriceLabel(bestPrice));
                    }
                    else {
                        setResultLabelData('Found itens with total proficiency of ' + statSum + ' for a total price of ' + displayPriceLabel(bestPrice));
                    }
                }
            }

            if (bestPrice == -1) {
                setResultLabelData('Could not find an adequate set matching requirements');
            }
            else {
                setBestItemsData(bestItemArray.map(e => allItems.filter(al => al.tokenId == e)[0]))
            }

        } catch (error) {
            console.log(error);
            setResultLabelData("An error occurred when running");
        }

        setLoading(false)
    }

    const updateSelectedSearchType = async (type: string) => {
        setProficiencyTypeData(type);

        if (type == 'combatdef') {
            setProficiencyLabel('combat def');
        }
        else if (type == 'plantingproficiency') {
            setProficiencyLabel('planting proficiency');
        }
        else if (type == 'grassproficiency') {
            setProficiencyLabel('gather grass proficiency');
        }
        else if (type == 'treeproficiency') {
            setProficiencyLabel('gather wood proficiency');
        }
        else if (type == 'oreproficiency') {
            setProficiencyLabel('gather ore proficiency');
        }
        else if (type == gatheringAll) {
            setProficiencyLabel(gatheringAll);
        }
    }

    useQuery(RON_TO_USD_QUERY, {
        fetchPolicy: 'cache-first', // Change this to control caching behavior
        onCompleted: (response) => {
            if (ronToUSDValue == 0) {
                setRonToUSDValue(response.exchangeRate.ron.usd);
            }
        }
    });

    useEffect(() => {
        const fetchData = async () => {
        }

        fetchData();

        return () => {
        }
    }, []);

    // if (!bestItemsData) return <div>loading</div>

    return (
        <Layout>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                <TextField label="current proficiency" defaultValue={currentProficiencyData} variant="outlined" onChange={e => setCurrentProficiencyData(e.target.value)} />
                <TextField label="goal proficiency" defaultValue={goalProficiencyData} variant="outlined" onChange={e => setGoalProficiencyData(e.target.value)} />
                <TextField label="min level" defaultValue={minLevel} variant="outlined" onChange={e => setMinLevel(parseInt(e.target.value))} />
                <TextField label="max level" defaultValue={maxLevel} variant="outlined" onChange={e => setMaxLevel(parseInt(e.target.value))} />
                <select onChange={e => updateSelectedSearchType(e.target.value)}>
                    <option value="">-- select --</option>
                    <option value="combatdef">combat def</option>
                    <option value="plantingproficiency">farming (planting)</option>
                    <option value={gatheringAll}>gathering (all)</option>
                    <option value="grassproficiency">gathering (grass)</option>
                    <option value="treeproficiency">gathering (wood)</option>
                    <option value="oreproficiency">gathering (mineral)</option>
                </select>
                <LoadingButton loading={loading} onClick={async () => await getAllItems()}>search</LoadingButton>
            </div>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                <FormControlLabel
                    control={
                        <Checkbox checked={enableHeadSearch} onChange={e => setEnableHeadSearch(e.target.checked)} name="helmetSearch" />
                    }
                    label="Head Piece"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={enableChestSearch} onChange={e => setEnableChestSearch(e.target.checked)} name="armorSearch" />
                    }
                    label="Chest Piece"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={enableHandSearch} onChange={e => setEnableHandSearch(e.target.checked)} name="glovesSearch" />
                    }
                    label="Gloves"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={enableLegsSearch} onChange={e => setEnableLegsSearch(e.target.checked)} name="pantsSearch" />
                    }
                    label="Pants"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={enableFeetSearch} onChange={e => setEnableFeetSearch(e.target.checked)} name="bootsSearch" />
                    }
                    label="Boots"
                />
            </div>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                <h2>{resultLabelData}</h2>
            </div>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                {
                    proficiencyTypeData == null ? <></> : bestItemsData?.map(e => (
                        e.tokenId == "0" ? <></> :
                            <ItemDisplay Item={e} showAttributes={false}>
                                <div>
                                    {
                                        proficiencyTypeData != gatheringAll ? (<>{proficiencyLabel} : {getAttributeValue(e, proficiencyTypeData)}</>) :
                                            (
                                                <>
                                                    <span>{proficiencyTypeLabels[0]} : {e.stat1}</span><br />
                                                    <span>{proficiencyTypeLabels[1]} : {e.stat2}</span><br />
                                                    <span>{proficiencyTypeLabels[2]} : {e.stat3}</span><br />
                                                </>
                                            )
                                    }
                                </div>
                                <div>
                                    level: {getAttributeValue(e, 'requires level')}
                                </div>
                            </ItemDisplay>
                    ))
                }
            </div>
        </Layout >
    );
};

export default SetBuilder;

