import { RON_TO_USD_QUERY, FILTER_ITEMS_QUERY, GET_ITEM_DETAILS_QUERY } from "../services/apiService"
import { useQuery, useLazyQuery, LazyQueryExecFunction, OperationVariables } from "@apollo/client";
import Item from "../interfaces/item";

export const getEneryRestoredFromPotion = (erc1155Token: any) => {
    if(erc1155Token.attributes == null) return 0;
    if(erc1155Token.attributes.length == 0) return 0;
    if(erc1155Token.attributes['restore energy'] == null) return 0;
    
    return erc1155Token.attributes['restore energy'][0];
}

export const getRonToUSD = () => {
    var getRonToUSDValue = 0;
    var exchangeRateString = localStorage.getItem('ronToUSDValue');
    if (exchangeRateString) {
        return parseFloat(exchangeRateString);
    }

    return 0;
}

export const getAttributeValue = (erc1155Token: any, key :string) => {
    if(erc1155Token.attributes == null) return 0;
    if(erc1155Token.attributes.length == 0) return 0;
    if(erc1155Token.attributes[key] == null) return 0;    
    return erc1155Token.attributes[key][0];
}

export const displayPriceLabel = (price : number, removeFee? : boolean) => {
    if(price == null) return '--';

    let ronToUsd = getRonToUSD();
    if(removeFee)
    {
        price = price * 0.9;
    }
    
    try {        
        return price.toFixed(2) + ' RON (' + (price * ronToUsd).toFixed(2) + ' USD)';    
    }
    catch(error) {
        console.error('displayPrice (' + price + ') ' + error);
    }
}

export const displayPrice = (item : Item, removeFee? : boolean) => {
    if(item == null) return '-- RON';
    if(item.minPrice == null) return '-- RON';
    let price = priceCalc(item.minPrice);
    let ronToUsd = getRonToUSD();

    if(removeFee)
    {
        price = price * 0.9;
    }
    try {        
        return price.toFixed(2) + ' RON (' + (price * ronToUsd).toFixed(2) + ' USD)';    
    }
    catch(error) {
        console.error('displayPrice (' + price + ') ' + error);
    }
}

export const loadItemsFromArray = async (idArray: number[], getItemDetailsQuery : LazyQueryExecFunction<any, OperationVariables>) => {
    var itemArray: Item[] = []
    if (idArray) {
        let asyncMap = idArray.map(async (id) => {
            itemArray.push(await getItem(id.toString(), getItemDetailsQuery));
        });

        const results = await Promise.all(asyncMap);
        console.log(itemArray);
    }

    return itemArray;
}

export const priceCalc = (longNumber: number) => {
    return longNumber / 1000000000000000000;
}

export const mapTokenToItem = (tokenId : string, erc1155Token : any) => 
{    
    // console.log('map token to item')

    let item : Item = {
        tokenId: tokenId,
        cdnImage: erc1155Token.cdnImage,
        minPrice: erc1155Token.minPrice,
        name: erc1155Token.name,
        cached: false,
        energy: getEneryRestoredFromPotion(erc1155Token),
        attributes: erc1155Token.attributes
    };

    // console.log(item)

    return item;
}

export const filterItemsFunction = async (name: string, query : LazyQueryExecFunction<any, OperationVariables>) => {    
    const response = await query({ variables: { name } });
    let array : Item[] = []
    response.data.erc1155Tokens.results.map((e: any) => {
        array.push(mapTokenToItem(e.tokenId, e));
    });
    return array;
}

export const loadAndRefreshDatabase = async (tokenId: string, query : LazyQueryExecFunction<any, OperationVariables>) => {

}

export const getItem = async (tokenId: string, query : LazyQueryExecFunction<any, OperationVariables>) => {
    const response = await query({ variables: { tokenId } });
    let erc1155Token = response.data.erc1155Token;
    if (erc1155Token.name == null || erc1155Token.minPrice == null) {
        var jsonString = localStorage.getItem(tokenId.toString());
        if (jsonString) {
            erc1155Token = JSON.parse(jsonString) as Item;
            erc1155Token.cached = true;
            //return erc1155Token;
        }
    }

    let item = mapTokenToItem(tokenId, erc1155Token);

    if(item.name == null || item.minPrice == null) {
        if(erc1155Token){
            item.name = erc1155Token.name;
            item.minPrice = erc1155Token.minPrice;
            item.attributes = erc1155Token.attributes;
        }
    }

    localStorage.setItem(tokenId.toString(), JSON.stringify(item));
    return item;
}
