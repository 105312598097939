import React, { useState, useEffect } from "react";
import Item from "../interfaces/item";
import { priceCalc, displayPrice, getRonToUSD } from "../services/helperServices"

interface ItemDisplayProps {
    Item: Item
    children: React.ReactNode;
    showAttributes: boolean;
}

const ItemDisplay: React.FC<ItemDisplayProps> = ({ Item, children, showAttributes }) => {
    return (
        <div style={{
            display: "flex", flexDirection: "column", justifyContent: "space-between", border: "1px solid white", padding: "15px", margin: "3px"
        }}>
            {children}
            <span style={{ fontSize: "7px" }}>{Item.tokenId}</span>
            <a style={{ fontSize: "7px", color: "lightblue" }} href={("https://marketplace.skymavis.com/collections/lumiterra/" + Item.tokenId)}>marketplace</a>
            <img style={{ width: "128px" }} src={Item.cdnImage}></img>
            <p>{Item.name}</p>
            <span>{displayPrice(Item)}</span>
            {
                Item.attributes == null || !showAttributes ? <></> :
                    Object.entries(Item.attributes)
                        .sort((a, b) => a[0] > b[0] ? 1 : -1)
                        .map(e =>
                        (
                            <div>{e[0]} {e[1] as string}</div>
                        ))
            }
        </div>
    );
};

export default ItemDisplay;