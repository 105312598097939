import React, { useState, useEffect } from "react";
import { useNavigate, To } from "react-router-dom";

interface LinkyProps {
    to: any,
    className: string | undefined,
    children: React.ReactNode;
}

const Linky: React.FC<LinkyProps> = ({ to, className = undefined, children }) => {
    const navigate = useNavigate();
    const startMenuNavigation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, to: any) => {
        event.preventDefault();
        navigate(to);
    };

    return (
        <a onClick={(e) => startMenuNavigation(e, to)} href={to} className={className} style={{ color: "white" }}>
            {children}
        </a>
    );
};

export default Linky;
