import React from "react";
import logo from "./logo.svg";
import "./assets/style.css";
import "./assets/fontawesome/css/all.min.css";
import "./App.css";

import Home from "./components/Home";
import Crafting from "./components/Crafting";
import Farming from "./components/Farming";
import SetBuilder from "./components/SetBuilder";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import EnergyPotions from "./components/EnergyPotions";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const App: React.FC = () => {
    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <Router>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/crafting" element={<Crafting />} />
                        <Route path="/energypotions" element={<EnergyPotions />} />
                        <Route path="/farming" element={<Farming />} />
                        <Route path="/setbuilder" element={<SetBuilder />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        </>
    );
};

export default App;
